
import {
  mdiLogin,
  mdiEmail,
  mdiEmailOutline,
  mdiLock,
  mdiLockOpen,
} from '@mdi/js'
import prx from '@/mixins/prx'

export default {
  auth: 'guest',
  mixins: [prx],
  layout: 'centered',

  $_veeValidate: {
    validator: 'new',
  },

  data() {
    const { baseUrl, registerFlag } = this.$config
    return {
      password: '',
      loading: false,
      register: registerFlag,
      register_url: `${baseUrl}signup?utm_source=Prx2_register_button`,
      icon: {
        login: mdiLogin,
        email: mdiEmail,
        emailOutline: mdiEmailOutline,
        locked: mdiLock,
        unlocked: mdiLockOpen,
      },
    }
  },

  computed: {
    email: {
      /** Email saved in local state */
      get() {
        return this.$store.state.local.email
      },
      set(value) {
        this.$store.commit('local/setEmail', value)
      },
    },
  },

  mounted() {
    // If the correct query params are passed, show an alert on load
    const { text, color } = this.$route.query
    if (text) {
      this.$alerts.custom(color, text)
    }
  },

  methods: {
    async handleSubmit(e) {
      e.preventDefault()

      this.loading = true
      this.$nuxt.$loading.start()

      const valid = await this.$validator.validateAll()
      if (valid) {
        try {
          // Login
          await this.$auth.loginWith('local', {
            data: {
              email: this.email,
              password: this.password,
            },
          })

          // Redirect to new rx if user hasn't viewed the WelcomeTour
          if (this.$store.getters['profile/showWelcomeTour']) {
            this.$router.push({ name: 'prescriptions-new' })
          } else {
            this.$router.push({ path: '/' })
          }
        } catch (e) {
          // Show error message
          this.$alerts.$emit({
            color: 'warning',
            ...this.parseError(e?.response?.status),
          })
        }
      } else {
        this.$alerts.error(
          'Existen errores en los campos, arréglalos antes de continuar'
        )
      }

      this.loading = false
      this.$nuxt.$loading.finish()
    },

    parseError(statusCode) {
      switch (statusCode) {
        case 406:
          return {
            message:
              'Tu correo no está validado, revisa tu bandeja de entrada o vuelve a enviar el correo de validación.',
            actionText: 'Reenviar correo de verificación',
            actionFn: () => this.$router.push('resend-confirmation'),
          }
        case 409:
          return {
            message: 'La cuenta no está activada, intenta más tarde.',
          }
        case 400:
        default:
          return {
            message:
              'No se pudo iniciar sesión. Revisa que las credenciales proporcionadas sean correctas.',
          }
      }
    },
  },
}
